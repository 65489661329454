@import 'variables';

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin rem-fallback($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value * $rem-to-pixels}px;
    @if $i < $max {
      $pxValues: #{$pxValues + ' '};
    }
  }

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + ' '};
    }
  }

  #{$property}: $pxValues;
  #{$property}: $remValues;
}
