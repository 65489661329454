/**
 * react-transition-group 'fade' animation
 * usage:
 *    <CSSTransition classNames="fade" />
*/

$distanceP: 15rem;
$distanceN: -15rem;

$rotationP: 360deg;
$rotationN: -360deg;

.fade-enter {
  opacity: 0.01;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes collapse-height {
  0% {
    max-height: 600px;
  }
  100% {
    max-height: 0px;
  }
}

@keyframes expand-height {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 600px;
  }
}

@keyframes collapse-padding {
  0% {
    padding: 2rem 2rem;
    margin-top: space(4);
    border-width: 1px;
  }
  100% {
    padding: 0rem 2rem;
    margin-top: 0;
    border-width: 0px;
  }
}

@keyframes expand-card {
  0% {
    border-width: 0px;
  }
  100% {
    border-width: 1px;
  }
}

@keyframes collapse-margin-top {
  0% {
    margin-top: space(4);
  }
  100% {
    margin-top: 0;
  }
}

@keyframes from-bottom {
  0% {
    transform: translateY(80vh);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes how-it-works-animate-up {
  0% {
    transform: translateY(10rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

@keyframes how-it-works-animate-up-iphone {
  0% {
    transform: translateY(10rem);
  }
  100% {
    transform: translateY(-6.4rem);
  }
}

@keyframes home-animate-up {
  0% {
    transform: translate(-50%, 10rem);
  }
  100% {
    transform: translate(-50%, 0rem);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fb {
  0% {
    transform: translateY($distanceP);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes fbl {
  0% {
    transform: translateY($distanceP) translateX($distanceN);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes fbr {
  0% {
    transform: translateY($distanceP) translateX($distanceP);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ft {
  0% {
    transform: translateY($distanceN);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ftl {
  0% {
    transform: translateY($distanceN) translateX($distanceN);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes ftr {
  0% {
    transform: translateY($distanceN) translateX($distanceP);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes fr {
  0% {
    transform: translateX($distanceP);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes fl {
  0% {
    transform: translateX($distanceN);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes rc {
  0% {
    transform: rotate($rotationN);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes rcc {
  0% {
    transform: rotate($rotationP);
  }
  100% {
    transform: rotate(0);
  }
}

.animate-spin-160 {
  animation: spin 160s infinite linear;
}

.animate-spin-180 {
  animation: spin 160s infinite linear;
}

/**
 * 404 animations
 */
@keyframes rectangles {
  0% {
    transform: translateY(35%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes left-heart {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes right-heart {
  0% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 24px rgba(0, 0, 0, 0);
  }
}

@keyframes autocomplete-green-gray-flicker {
  0% {
    background: white;
  }
  50% {
    background: $Capsule-green-lighter-70;
  }
}

@keyframes autocomplete-green-flicker {
  0% {
    background: $Capsule-green-lighter-90;
  }
  15% {
    color: white;
  }
  50% {
    background: $Capsule-green-60-new;
  }
  70% {
    color: white;
  }
  100% {
    background: $Capsule-green-lighter-90;
  }
}

// transfer interstitial expanding heart
@keyframes heart-expand {
  0% {
    transform: scale(0.67);
    animation-timing-function: cubic-bezier(0.66, 0, 0.66, 1);
  }
  20% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
    animation-timing-function: cubic-bezier(0.9, 0, 0.54, 1);
  }
  80% {
    transform: scale(1.33);
  }
}

@keyframes heart-fade {
  0% {
    opacity: 0;
    animation-timing-function: cubic-bezier(1, 0, 0.66, 1);
  }
  26% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    animation-timing-function: cubic-bezier(1, 0, 0.5, 1);
  }
  80% {
    opacity: 0%;
  }
}
