@import 'math';

$circular: Circular-Pro, Helvetica, Arial, sans-serif;
$mercury: MercuryTextG4, Georgia, 'Times New Roman', serif;

@mixin header {
  font-family: $circular;
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: normal;
}

@mixin sub-header {
  font-family: $circular;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: normal;
}

@mixin body {
  font-family: $circular;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: normal;
}

@mixin body-terse {
  font-family: $circular;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: normal;
}

@mixin body-bold {
  font-family: $circular;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: normal;
}

@mixin micro-copy {
  font-family: $circular;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: normal;
}

@mixin label-callout {
  font-family: $mercury;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 0.15rem;
}

@mixin label-overline {
  font-family: $circular;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 0.15rem;
}

@mixin label-subheader {
  font-family: $circular;
  font-weight: 300;
  line-height: 2.0;
  letter-spacing: normal;
}

$base-font-size: 1.6rem;
$font-scale: 1.125;
$font-sizes: (0: $base-font-size);
@for $i from 1 through 16 {
  $font-sizes: map-merge($font-sizes, ($i: $base-font-size * pow($font-scale, $i)));
}
@for $j from 1 through 6 {
  $font-sizes: map-merge($font-sizes, (-$j: $base-font-size / pow($font-scale, $j)));
}

/**
 * This will mix in the font-size based on our font scaling rules.
 *
 * @param $mobile-size: The size of the font by default, on mobile devices (-3 -> 7)
 * @param $desktop-size: The size of the font on a wide enough screen (-3 -> 7), overrides $mobile-size
 */
@mixin font-size($mobile-size, $desktop-size: $mobile-size) {
  font-size: map-get($font-sizes, $mobile-size);

  @include media-breakpoint-up(md) {
    font-size: map-get($font-sizes, $desktop-size);
  }
}

@mixin font-style($style: 'standard') {
  @if ($style == 'standard') {
    color: $Capsule-blue;
  } @else if ($style == 'standard-dark') {
    color: $Capsule-blue-darker-20;
  } @else if ($style == 'strong') {
    color: $Capsule-red-60-new;
  } @else if ($style == 'inverted') {
    color: $white;
  } @else if ($style == 'contrast') {
    color: $Capsule-green-60-new;
  } @else if ($style == 'system-blue') {
    color: $system-blue;
  } @else if ($style == 'footer-section') {
    color: $Capsule-gray-darker-30;
  } @else if ($style == 'inactive') {
    color: $Capsule-blue-lighter-50;
  } @else if ($style == 'black') {
    color: $Black;
  }
}

@mixin font-type($type) {
  @if ($type == 'header') {
    @include header;
  } @else if($type == 'sub-header') {
    @include sub-header;
  } @else if ($type == 'label-callout') {
    @include label-callout;
  } @else if ($type == 'label-overline') {
    @include label-overline;
  } @else if ($type == 'label-subheader') {
    @include label-subheader;
  } @else if ($type == 'body') {
    @include body;
  } @else if ($type == 'body-terse') {
    @include body-terse;
  } @else if ($type == 'body-bold') {
    @include body-bold;
  } @else if ($type == 'micro-copy') {
    @include micro-copy;
  }
}

@mixin font($type, $style: null, $mobile-size: 0, $desktop-size: $mobile-size) {
  @include font-type($type);
  @include font-style($style);
  @include font-size($mobile-size, $desktop-size);
}
