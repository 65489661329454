@import 'variables';
@import 'math';
@import 'fonts';

.marketing {
  @include font($type: 'body', $style: 'standard', $mobile-size: -1, $desktop-size: 0);
  overflow-x: hidden;

  $marketing-header-sizes: (
    4: (
      desktop: $font-up-11,
      mobile: $font-up-9,
    ),
    3: (
      desktop: $font-up-8,
      mobile: $font-up-6,
    ),
    2: (
      desktop: $font-up-4,
      mobile: $font-up-2,
    ),
    1: (
      desktop: $font-up-1,
      mobile: $base-font-size,
    ),
  );

  $marketing-body-sizes: (
    1: (
      desktop: $base-font-size,
      mobile: $font-down-1,
    ),
  );

  @each $item, $size in $marketing-header-sizes {
    .font-h-#{$item} {
      font-size: map-get($size, mobile);

      @include media-breakpoint-up(md) {
        font-size: map-get($size, desktop);
      }
    }

    @each $item, $size in $marketing-header-sizes {
      .font-b-#{$item} {
        font-size: map-get($size, mobile);

        @include media-breakpoint-up(md) {
          font-size: map-get($size, desktop);
        }
      }
    }

    .font-label-callout {
      @include label-callout;
    }

    .font-label-overline {
      @include label-overline;
    }

    .font-sub-header {
      @include sub-header;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 1.2rem 0;
      margin-bottom: 1rem;
    }

    h1,
    .h1 {
      @include font($type: 'header', $style: 'standard', $mobile-size: 4, $desktop-size: 13);
    }

    h2,
    .h2 {
      @include font($type: 'header', $style: 'standard', $mobile-size: 3, $desktop-size: 10);
    }

    h3,
    .h3 {
      @include font($type: 'header', $style: 'standard', $mobile-size: 2, $desktop-size: 7);
    }

    h4,
    .h4 {
      @include font($type: 'header', $style: 'standard', $mobile-size: 1, $desktop-size: 5);
    }

    h5,
    .h5 {
      @include font($type: 'header', $style: 'standard', $mobile-size: 0, $desktop-size: 3);
    }

    h6,
    .h6 {
      @include font($type: 'header', $style: 'standard', $mobile-size: -1, $desktop-size: -1);
    }

    p {
      margin: 0 0 1.5rem 0;
      @include font($type: 'body', $style: 'standard', $mobile-size: 0, $desktop-size: 0);
    }

    a {
      color: $Capsule-green-60-new;
      text-decoration: none;
      cursor: pointer;
      svg {
        fill: $Capsule-green-60-new;
      }

      &:hover {
        text-decoration: underline;
      }

      .btn {
        &-primary {
          color: #fff;
          padding-top: 0.9rem;
          min-width: 240px;
        }
      }
    }

    @for $i from 1 through 15 {
      .font-up-#{$i} {
        @include font-size($i);
      }
    }

    @for $i from 1 through 3 {
      .font-down-#{$i} {
        @include font-size(-$i);
      }
    }

    @each $screen-size in 'xs', 'sm', 'md', 'lg' {
      @include media-breakpoint-up($screen-size) {
        @for $font-size from 1 through 13 {
          .#{$screen-size}-font-up-#{$font-size} {
            @include font-size($font-size);
          }
        }

        @for $font-size from 1 through 3 {
          .#{$screen-size}-font-down-#{$font-size} {
            @include font-size(-$font-size);
          }
        }
      }
    }

    //
    // text modifiers
    //

    .center {
      text-align: center;
    }

    .align-left {
      text-align: left !important;
    }

    .align-right {
      text-align: right;
    }

    .uppercase {
      text-transform: uppercase;
    }

    .underline {
      text-decoration: underline;
    }

    .letter-spacing-025 {
      letter-spacing: 0.125rem;
    }

    .letter-spacing-3 {
      letter-spacing: 0.3rem;
    }

    .main-font-down-1 {
      p {
        font-size: $font-down-1;
      }
    }

    //
    // colors
    //

    .teal {
      color: $Capsule-green-lighter-50;
    }

    .green {
      color: $Capsule-green-60-new;
    }

    .white {
      color: white;
    }

    .red {
      color: $Capsule-red-60-new;
    }

    .blue {
      color: $Capsule-blue;
    }

    .yellow {
      color: $Capsule-yellow;
    }

    .gray {
      color: $Capsule-gray-darker-30;
    }
  }
}
