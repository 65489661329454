/* Circular Pro */
@font-face {
  font-display: optional;
  font-family: 'Circular-Pro';
  src: url('/public/lineto-circular-pro-book.subset.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: optional;
  font-family: 'Circular-Pro';
  src: url('/public/lineto-circular-pro-bookitalic.subset.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-display: optional;
  font-family: 'Circular-Pro';
  src: url('/public/lineto-circular-pro-bold.subset.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: optional;
  font-family: 'Circular-Pro';
  src: url('/public/lineto-circular-pro-bolditalic.subset.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-display: optional;
  font-family: 'Circular-Pro';
  src: url('/public/lineto-circular-pro-medium.subset.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: optional;
  font-family: 'Circular-Pro';
  src: url('/public/lineto-circular-pro-mediumitalic.subset.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 500;
  font-style: italic;
}

/* Mercury Text G4 */
@font-face {
  font-display: optional;
  font-family: 'MercuryTextG4';
  src: url('/public/mercury-text-g4-bold-pro.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: optional;
  font-family: 'MercuryTextG4';
  src: url('/public/mercury-text-g4-bold-italic-pro.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-display: optional;
  font-family: 'MercuryTextG4';
  src: url('/public/mercury-text-g4-italic-pro.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-display: optional;
  font-family: 'MercuryTextG4';
  src: url('/public/mercury-text-g4-roman-pro.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: optional;
  font-family: 'MercuryTextG4';
  src: url('/public/mercury-text-g4-semi-bold-pro.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-display: optional;
  font-family: 'MercuryTextG4';
  src: url('/public/mercury-text-g4-semi-italic-pro.woff2') format('woff2');
  unicode-range: U+0000-007f;
  font-weight: 600;
  font-style: italic;
}
