@mixin default-button {
  text-align: center;
  // adding border to ensure identical sizing between primary and secondary
  border-style: solid;
  @include rem-fallback(border-width, 0.1);
  border-radius: $border-radius-small;
  letter-spacing: 0.15rem;
  padding: 0 2.4rem;
  cursor: pointer;
  transition: background-color $animation-speed-fast, border-color $animation-speed-fast;
  margin: space(3) 0;
  display: inline-block;
  white-space: pre;
}
// This Button has an invisible border so that it is the same size as the secondary button and doesnt move content when they toggle
@mixin button-primary {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0); // don't remove unless you make the buttons the same size
  @include rem-fallback(border-width, 0.1);
  background-color: $Capsule-red-60-new;
  background-clip: padding-box;
  @include font($type: 'label-callout', $style: 'inverted', $mobile-size: 0, $desktop-size: 0);

  &:hover {
    background-color: $Capsule-red-darker-20;
    text-decoration: none;
    color: $white;
  }
  &:focus {
    outline-offset: -0.6rem;
    outline-width: 0.2rem;
    outline-style: solid;
    outline-color: white;
  }
  &:active {
    background-color: $Capsule-red-60-new;
  }
}

@mixin button-secondary {
  border-style: solid;
  border-color: $Capsule-blue;
  @include rem-fallback(border-width, 0.1);
  @include font($type: 'label-callout', $style: 'standard', $mobile-size: 0, $desktop-size: 0);
  background-color: rgba(255, 255, 255, 0.5);
  &:hover {
    background-color: $Capsule-blue-darker-20;
    @include font-style('inverted');
    text-decoration: none;
  }

  &:active,
  &:focus {
    background-color: $Capsule-blue;
    @include font-style('inverted');
  }
}

@mixin button-tertiary {
  // adding border to ensure identical sizing between primary and secondary
  border-style: solid;
  border-color: transparent;
  color: $Capsule-blue;
  @include rem-fallback(border-width, 0.1);
  @include font($type: 'label-callout', $style: 'standard', $mobile-size: 0, $desktop-size: 0);
  background-color: transparent;
  text-decoration: underline;
  text-underline-offset: 0.4rem;

  &:hover {
    text-decoration: none;
  }

  &:focus,
  &:active {
    text-decoration: underline;
    text-decoration-thickness: 0.2rem;
  }

  &:hover,
  &:focus,
  &:active {
    color: $Capsule-blue-darker-20;
    text-decoration-color: $Capsule-blue-darker-20;
  }
}

@mixin button-size-large {
  width: 100%;
  padding: 2.3rem 2.4rem;
}

@mixin button-size-medium {
  padding: 1.4rem 1.8rem;
  min-height: 4.8rem;
  @include font-size(-1);
}

@mixin button-size-medium-large-margin {
  padding: 0;
  margin: 3.5rem 0;
  @include font-size(-1);
}

@mixin button-size-medium-small-margin {
  padding: 0;
  margin: 2rem 0 0;
  @include font-size(-1);
}

@mixin button-size-small {
  padding: 0.8rem 1.2rem;
  border-radius: $border-radius-medium;
  @include font-size(-1);
}

@mixin button-size-extra-small {
  border-radius: $border-radius-medium;
  @include font-size(-3);
}

@mixin button-size-slim-wide {
  padding: 1.6rem 5.8rem;
  border-radius: $border-radius-medium;
  @include font-size(-1);
}

@mixin button-size-small-wide {
  padding: 0.8rem 2.2rem;
  border-radius: $border-radius-medium;
  @include font-size(-3);
}

@mixin button-block {
  width: 100%;
}

@mixin button-fixed-width-small {
  width: 100%;
  max-width: 9rem;
}

@mixin button-fixed-width-medium {
  width: 100%;
  max-width: 24rem;
}

@mixin button-primary-disabled {
  background-color: $Capsule-gray-10;
}

@mixin button-secondary-disabled {
  color: $Capsule-gray-10;
  border-color: $Capsule-gray-10;
}
