$horizontal-padding: 3.5rem;
$horizontal-left-padding: 3rem;
$horizontal-right-padding: 3.5rem;

#app {
  .u-position-absolute {
    position: absolute;
  }

  .u-position-relative {
    position: relative;
  }

  .accordion {
    border-radius: $border-radius-small;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 87%;
    }
  }

  .fullWidthAccordion {
    border-radius: $border-radius-small;
    width: 100%;
  }

  .accordion__item {
    border-top: 1px solid $Capsule-gray;
    padding: 0.4rem 0;

    &--has-icon {
      position: relative;
    }

    &:last-child {
      border-bottom: 1px solid $Capsule-gray;
    }
  }

  .new_accordion_item {
    border-top: 1px solid #dfecea;
    padding: 0.4rem 0;

    &--has-icon {
      position: relative;
    }

    &:last-child {
      border-bottom: 1px solid #dfecea;
    }
  }

  .accordion__archived__item {
    margin-top: space(5);
  }

  .accordion__heading {
    color: $Capsule-blue;
    cursor: pointer;
    width: 100%;
    text-align: left;
    margin-bottom: space(3);

    h2,
    h3,
    h4 {
      margin: 0;
      padding-left: $horizontal-padding;
      padding-right: $horizontal-padding;
    }
  }

  .archived__accordion__heading {
    color: $Capsule-blue;
    cursor: pointer;
    width: 100%;
    text-align: left;

    h2,
    h3 {
      margin: 0;
      padding-left: $horizontal-left-padding;
      padding-right: $horizontal-right-padding;
    }
  }

  .archived__accordion__panel {
    display: block;
    animation: accordion-fadein 0.5s ease-in;
    color: $Capsule-blue;
  }

  .accordion__panel {
    padding: 0 $horizontal-padding 1rem $horizontal-padding;
    display: block;
    animation: accordion-fadein 0.5s ease-in;
    color: $Capsule-blue;
  }

  .accordion__archived__body {
    display: block;
    animation: accordion-fadein 0.5s ease-in;
    color: $Capsule-blue;
  }

  .accordion__panel--hidden {
    display: none;
    opacity: 0;
    animation: accordion-fadein 0.5s ease-in;
  }

  .accordion__heading > *:last-child,
  .accordion__panel > *:last-child {
    margin-bottom: 0;
  }

  .accordion__arrow {
    display: inline-block;
    color: $Capsule-red-60-new;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -6px;
    transform: rotate(-90deg);
    transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  }

  .new_accordion__arrow::after,
  .new_accordion__arrow::before {
    background-color: $Capsule-blue !important;
  }

  .v2_arrow::after,
  .v2_arrow::before {
    background-color: #008674 !important;
  }

  .accordion__arrow::after,
  .accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: $Capsule-red-60-new;
    content: '';
  }

  .accordion__arrow::before {
    left: 4px;
    transform: rotate(45deg);
  }

  [aria-expanded='true'] .accordion__arrow,
  [aria-selected='true'] .accordion__arrow {
    transform: rotate(0deg);
  }

  .accordion__arrow::after {
    right: 4px;
    transform: rotate(-45deg);
  }
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes accordion-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
