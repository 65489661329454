
.hide {
  display: none;
}

.relative {
  position: relative;
}

.clearfix {
  width: 100%;

  &:before {
    clear: both;
    content: '';
    display: table;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.width-100 {
  width: 100%;
}

.max-width-6 {
  max-width: 128rem;
  width: 100%;
  margin: auto;
}

.max-width-5 {
  max-width: 118rem;
  width: 100%;
  margin: auto;
}

.max-width-4 {
  max-width: 108rem;
  width: 100%;
}

.max-width-2 {
  max-width: 60rem;
  width: 100%;
}

.section--dark {
  background: $Capsule-blue;
  color: $white;

  p {
    color: $white;
  }

  h1,h2,h3,h4,h5,h6 {
    color: $white;
  }
}

.section--light {
  background: $Capsule-green-lighter-90;
  color: $Capsule-blue;
}

.section--medium {
  background: $Capsule-green-lighter-70;
  color: $Capsule-blue;
}

.section--red {
  background-color: $Capsule-red-60-new;
}

.section--white {
  background-color: white;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.sm-show {
  display: none;
}

.md-show {
  display: none;
}

.lg-show {
  display: none;
}

.lg-show-inline {
  display: none;
}

.tiny-hide {
  display: none;
}

@media (min-width: 321px) {
  .tiny-hide {
    display: block;
  }
}

@include media-breakpoint-up(sm) {
  .sm-hide {
    display: none;
  }

  .sm-show {
    display: block;
  }
}


@include media-breakpoint-up(lg) {
  .lg-hide {
    display: none;
  }

  .lg-show {
    display: block;
  }

  .lg-show-inline {
    display: inline-block;
  }
}

.container-full-width {
  width: 100%;
  max-width: 100%;
}

@include media-breakpoint-up(md) {
  .wide-container-padding {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }

  .normal-container-padding {
    padding-left: 14.3%;
    padding-right: 14.3%;
  }

  .md-hide {
    display: none;
  }

  .md-show {
    display: block;
  }
}
