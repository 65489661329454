@import 'base';

.logged-in {
  a {
    color: $Capsule-blue;
    text-decoration: none;

    &:hover {
      color: $Capsule-green-60-new;
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: space(3) 0;

    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }

  h1,
  .title-1 {
    @include font('header', 'standard', 4, 5);
  }

  h2,
  .title-2 {
    @include font('header', 'standard', 2, 3);
  }

  h3,
  .title-3 {
    @include font('header', 'standard', 0, 1);
  }

  h4,
  .title-4 {
    @include font('header', 'standard', -1, 0);
  }

  .normal {
    @include font('body-terse', 'standard', -1, 0);
  }

  strong,
  p,
  p.normal {
    @include font('body', 'standard', -1, 0);
  }

  small,
  .small {
    @include font('body-terse', 'standard', -2, -1);
  }

  .micro {
    @include font('sub-head', 'standard', -3, -3);
  }

  .label {
    @include font('label-overline', 'standard', -2, -2);
  }

  strong,
  .strong {
    @include font-style('strong');
  }

  .contrast {
    @include font-style('contrast');
  }
}
