$font-increment: 0.4;

$small-font-size: 12px;
$font-size: 1.6rem;
$medium-font-size: 1.6rem;
$label-font-size-medium: 1.2rem;

$rem-to-pixels: 10;

//Font Increments
$font-up-1: 1.8rem; // 18
$font-up-2: $font-up-1 * 1.125;
$font-up-3: $font-up-2 * 1.125;
$font-up-4: $font-up-3 * 1.125;
$font-up-5: $font-up-4 * 1.125;
$font-up-6: $font-up-5 * 1.125;
$font-up-7: $font-up-6 * 1.125;
$font-up-8: $font-up-7 * 1.125;
$font-up-9: $font-up-8 * 1.125;
$font-up-10: $font-up-9 * 1.125;
$font-up-11: $font-up-10 * 1.125;
$font-up-12: $font-up-11 * 1.125;
$font-up-13: $font-up-12 * 1.125;
$font-up-14: $font-up-13 * 1.125;
$font-up-15: $font-up-14 * 1.125;

//Font Decrements
$font-down-1: $font-size - 0.2; //14
$font-down-2: $font-down-1 - 0.15; //12.5
$font-down-3: $font-down-2 - 0.15; //11

// Sizing
$h1-size: $font-up-4;
$h2-size: $font-up-3;
$h3-size: $font-up-2;
$h4-size: $font-up-1;
$h5-size: $font-size;
$h6-size: $font-down-1;

// Line height
$h-line-height: 1.25;
$p-line-height: 1.5;

//spacing
$spacer: 0.4rem;
$spacers: () !default;

/*
  REMINDER: DO NOT AUTO FORMAT THE CODE BELOW.
  Doing so will format $spacers shown below from
  (
    $spacer * 1
  ),

  to

  (
    $spacer * 1,
  ),

  * which causes the map-merge to break due to the extra comma.
  */
$spacers: map-merge(
  (
    /* prettier-ignore */ 0: 0,
    /* prettier-ignore */ 1: ($spacer * 1),
    /* prettier-ignore */ 2: ($spacer * 2),
    /* prettier-ignore */ 3: ($spacer * 3),
    /* prettier-ignore */ 4: ($spacer * 5),
    /* prettier-ignore */ 5: ($spacer * 8),
    /* prettier-ignore */ 6: ($spacer * 13),
    /* prettier-ignore */ 7: ($spacer * 21),
    /* prettier-ignore */ 8: ($spacer * 34),
    /* prettier-ignore */ 9: ($spacer * 55)
  ),
  $spacers
);

@function space($amount: 1) {
  @return map-get($spacers, $amount);
}

$nav-height: 10rem;

$logged-in-sidebar-width: 26.8rem;
$logged-in-desktop-content-margin: space(6);
$logged-in-desktop-content-max-width: 96rem;
$logged-in-header-height: 8rem;

$marketing-header-height: 8rem;

$animation-speed-extra-fast: 100ms;
$animation-speed-fast: 250ms;
$animation-speed-slow: 500ms;
$animation-speed-extra-slow: 1s;

$standard-shadow-color: rgba(0, 0, 0, 0.06);
$dark-shadow-color: rgba(0, 0, 0, 0.12);

$grid-gutter-width: space(4);

$z-index-menu: 1001;
$z-index-popper: 1001;
$z-index-overlay: 1000;
$z-index-overlay-everything: 99999999;

$border-radius-small: 0.3rem;
$border-radius-medium: 0.5rem;
$border-radius-large: 0.6rem;

$new-theme-large-space: 4.8rem;
$new-theme-medium-space: 2.4rem;

$card-border-height: 0.1rem;
$card-item-padding: space(4);
