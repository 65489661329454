.form-frame {
  margin: 0 auto;
  @include rem-fallback(margin-bottom, 6);
  @include rem-fallback(padding, 0, 4);
  max-width: 400px;
  width: 100%;
  color: $Capsule-blue;
}

.form-footer {
  @include rem-fallback(margin, 2.5);
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  p {
    padding: 0;
  }
}