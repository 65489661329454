$Black: #1f1f1f;
$white: #ffffff;

// grays
$Capsule-gray-darker-90: #313439;
$Capsule-gray-darker-60: #5d626b;
$Capsule-gray-darker-30: #9199a7;
$Capsule-gray-darker-20: #a9b1bf;
$Capsule-gray: #b6bfce;
$Capsule-gray-10: #C8CCD0;
$Capsule-gray-50-new: #6E7781;
$Capsule-gray-lighter-30: #dadee4;
$Capsule-gray-lighter-50: #dadfe6;
$Capsule-gray-lighter-90: #f7f7f7;

// charcoal
$Capsule-charcoal: #333333;
$Capsule-charcoal-lighter-30: #666666;
$Capsule-charcoal-lighter-60: #999999;

// other
$system-gray-lighter-10: #f7f7f7;
$charcoal-gray: #333333;
$system-backshadow: rgba(27, 52, 88, 0.5);

// reds
$Capsule-red-10-new: #fd806d;
$Capsule-red-darker-20: #bc1600;
$Capsule-red-darker-10: #d63018;
$Capsule-red-60-new: #e72508;
$Capsule-brand-red: #ef4931;
$Capsule-red-lighter-50: #f7a498;

// blues
$Capsule-blue-darker-20: #1b3458;
$Capsule-blue: #0f3c6c;
$Capsule-blue-lighter-20: #27507b;
$Capsule-blue-lighter-50: #879db5;
$system-field-focus-blue: #4d90fe;
$system-blue: #037aff;

// greens
$Capsule-green-darker-20: #5b8d89;
$Capsule-green-60-new: #008674;
$Capsule-green-darkest: #007566;
$Capsule-brand-green: #74a6a2;
$Capsule-green-50-new: #99BFBC;
$Capsule-green-lighter-50: #99bfbc;
$Capsule-green-lighter-70: #dfecea;
$Capsule-green-lighter-90: #eff5f5;

// yellows
$Capsule-yellow: #ffd300;

/**
* new design system colors
* https://www.figma.com/file/z94hsrxy0xI7OnQ67fdaP4/System-0.9?node-id=191%3A124
*/
$Green-10: #dfecea;
$Grey-50: #6e7881;
$Green-60: #008674;
