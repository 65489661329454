
.border-bottom {
  border-bottom-style: solid;
  @include rem-fallback(border-bottom-width, .1);
}

@include media-breakpoint-up(lg) {

  .lg-border-right {
    border-right-style: solid;
    @include rem-fallback(border-right-width, .1);
  }

  .sm-border-bottom-none {
    border: none;
  }
}

.border-teal {
  border-color: $Capsule-green-60-new;
}