@import 'base';
@import 'buttonMixins';

a.link-btn {
  @include default-button;

  &__primary {
    @include button-primary;
  }

  &__secondary {
    @include button-secondary;
  }

  &__tertiary {
    @include button-tertiary;
  }

  &__large {
    @include button-size-large;
  }

  &__medium {
    @include button-size-medium;
    @include button-fixed-width-medium;
  }

  &__small {
    @include button-size-small;
    @include button-fixed-width-small;
  }

  &__block {
    @include button-block;
    max-width: none;
  }
}
