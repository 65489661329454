
.fill-yellow svg * {
  fill: $Capsule-yellow !important;
}

.fill-red svg * {
  fill: $Capsule-brand-red !important;
}

.fill-teal svg * {
  fill: $Capsule-brand-green !important;
}

.fill-gray svg * {
  fill: $Capsule-gray !important;
}